// @ts-nocheck
import {Column} from 'react-table'
import {AgentInfoCell} from './AgentInfoCell'
import {AgentLastLoginCell} from './AgentLastLoginCell'
import {AgentTwoStepsCell} from './AgentTwoStepsCell'
import {AgentActionsCell} from './AgentActionsCell'
import {AgentSelectionCell} from './AgentSelectionCell'
import {AgentCustomHeader} from './AgentCustomHeader'
import {AgentSelectionHeader} from './AgentSelectionHeader'
import {Agent} from '../../core/_models'

const agentsColumns: ReadonlyArray<Column<Agent>> = [
  // {
  //   Header: (props) => <AgentSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <AgentSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    accessor: 'agentName',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <AgentCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
  //   accessor: 'userRole',
  // },
  // {
  //   Header: (props) => <AgentCustomHeader tableProps={props} title='Last login' className='min-w-125px' />,
  //   accessor: 'userLastLoginTs',
  //   // Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {

  {
    Header: (props) => (
      <AgentCustomHeader tableProps={props} title='Actions' className=' min-w-100px'/> //text-end
    ),
    id: 'actions',
    Cell: ({...props}) => <AgentActionsCell id={props.data[props.row.index].agentId}/>,
  },
]

export {agentsColumns}
