/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Topic} from "../../../modules/topic/core/_models";
import {DashBoardTableLoading} from "../core/DashBoardTableLoading";

type Props = {
    className: string
    topics?: Topic[],
}

const TopicStatisticTable: React.FC<Props> = ({className, topics}) => {
    if (topics == null || topics == undefined || topics == []) {
        return (
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Topic Statistics</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='w-25px'>
                                </th>
                                <th className='min-w-150px'>Topic</th>
                                <th className='min-w-140px'>Keyword</th>
                                <th className='min-w-120px'>Number of Messages</th>
                                <th className='min-w-100px '>Last Updated</th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody >
                            <DashBoardTableLoading/>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        )
    } else {
        return (
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Topic Statistics</span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='w-25px'>
                                </th>
                                <th className='min-w-150px'>Topic</th>
                                <th className='min-w-140px'>Keyword</th>
                                <th className='min-w-120px'>Number of Messages</th>
                                <th className='min-w-120px'>Number of Comments</th>
                                <th className='min-w-120px'>Number of Conversations</th>
                                <th className='min-w-100px '>Message Last Updated </th>
                                <th className='min-w-100px '>Comment Last Updated </th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            <>
                                {topics.map((topic, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div
                                                    className='form-check form-check-sm form-check-custom form-check-solid'>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <span className='text-dark fw-bold fs-6'>
                                                            {topic.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold d-block fs-6'>
                                                    {topic.topTwoKeywordName!.length >= 2 ? topic.topTwoKeywordName![0] + ", " + topic.topTwoKeywordName![1] :
                                                        topic.topTwoKeywordName!.length >= 1 ? topic.topTwoKeywordName![0] : "No Record"}
                                                </span>
                                            </td>
                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2 '>
                                                        <span
                                                            className='text-muted me-2 fs-7 fw-semibold '>{topic.numOfMessage}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2 '>
                                                        <span
                                                            className='text-muted me-2 fs-7 fw-semibold '>{topic.numOfComments}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2 '>
                                                        <span
                                                            className='text-muted me-2 fs-7 fw-semibold '>{topic.numOfConversations}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-1 '>
                                            <span
                                                className='text-muted me-2 fs-7 fw-semibold '>{topic.masterUpdateTs}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-1 '>
                                            <span
                                                className='text-muted me-2 fs-7 fw-semibold '>{topic.commentUpdateTs}</span>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                )}
                            </>
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        )
    }
}

export {TopicStatisticTable}
