import axios, {AxiosResponse} from "axios";
import {DashBoardQueryResponse} from "./_model";


const API_URL = `/api/private/dashboard`

const getDashBoard = (query : string) : Promise<DashBoardQueryResponse> => {
    return axios
        .get(`${API_URL}/getDashBoard?${query}`)
        .then((d : AxiosResponse<DashBoardQueryResponse>) => d.data)
}

export {getDashBoard}