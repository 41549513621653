/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

type Props = {
    className: string
    description: string
    stats: number
    color: string,
}

const LastWeekMessages = ({className, description, stats, color}: Props) => (
    <div className={`card card-flush ${className}`} style={{backgroundColor: color}}>
        <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
                <div className='card-title d-flex flex-column'>
                    <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
                    <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
                </div>
            </div>
        </div>
    </div>

)
export {LastWeekMessages}
