/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
    className: string
    activeEmailDigestNum: number,
    emailSentDay: number,
    emailSentMonth: number
}

const EmailDigestDashboard = ({className, activeEmailDigestNum, emailSentDay, emailSentMonth}: Props) => (
  <div className={`card card-flush ${className}`} style={{backgroundColor: '#fff5f8'}}>
      <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
              <div className="card-title d-flex flex-column">
                  <div className="d-flex align-items-center">
                      <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">Email Digest Summary</span>
                  </div>
                  <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Active digests: {activeEmailDigestNum}</span>
                  <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Digests (24 hours): {emailSentDay}</span>
                  <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Digest (30 days): {emailSentMonth}</span>
              </div>
          </div>
      </div>
  </div>

)
export {EmailDigestDashboard}
