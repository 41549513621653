import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ClientsListWrapper} from './clients-list/ClientsList'

const usersBreadcrumbs: Array<PageLink> = [
  // {
  //   title: 'Client Management',
  //   path: '/apps/client-management/clients',
  //   isSeparator: false,
  //   isActive: false,
  // },
  // {
  //   title: '',
  //   path: '',
  //   isSeparator: true,
  //   isActive: false,
  // },
]

const ClientPage = () => {
  return (
    <Routes>
      <Route element={<Outlet/>}>
        <Route
          path='clients'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Client Management</PageTitle>
              <ClientsListWrapper/>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/client-management/clients'/>}/>
    </Routes>
  )
}

export default ClientPage
