// @ts-nocheck
import {Column} from 'react-table'
import {ClientInfoCell} from './ClientInfoCell'
import {ClientLastLoginCell} from './ClientLastLoginCell'
import {ClientTwoStepsCell} from './ClientTwoStepsCell'
import {ClientActionsCell} from './ClientActionsCell'
import {ClientSelectionCell} from './ClientSelectionCell'
import {ClientCustomHeader} from './ClientCustomHeader'
import {ClientSelectionHeader} from './ClientSelectionHeader'
import {Client} from '../../core/_models'
import {UserCustomHeader} from '../../../../user-management/users-list/table/columns/UserCustomHeader'
import {UserActionsCell} from '../../../../user-management/users-list/table/columns/UserActionsCell'




const clientsColumns: ReadonlyArray<Column<Client>> = [
  // {
  //   Header: (props) => <ClientSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ClientSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Name' className='min-w-125px'/>,
    accessor: 'clientName',
    // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <AgentCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
  //   accessor: 'userRole',
  // },
  // {
  //   Header: (props) => <AgentCustomHeader tableProps={props} title='Last login' className='min-w-125px' />,
  //   accessor: 'userLastLoginTs',
  //   // Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },


  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Agent' className=' min-w-100px'/> //text-end
    ),
    id: 'Agent',
    accessor:'clientAgentName',
  },


  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Actions' className=' min-w-100px'/> //text-end
    ),
    id: 'actions',
    Cell: ({...props}) => <ClientActionsCell id={props.data[props.row.index].clientId}/>,
  },
]

export {clientsColumns}
