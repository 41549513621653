import axios, {AxiosResponse} from 'axios'
import {ID, infoWindow, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {Agent} from '../../../agent-management/agents-list/core/_models'

const USER_URL = `/api/private/user`
const AGENT_URL = `/api/private/agent`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  // axios
  //   .get(`${USER_URL}/getUserList?${query}`)
  //   .then((d: AxiosResponse<UsersQueryResponse>) => {d.data; console.log(d)})

  return axios
    .get(`${USER_URL}/getUserList?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {

  // return axios
  //   .get(`${USER_URL}/getUserById/${id}`)
  //   .then((response: AxiosResponse<Response<User>>) => response.data)
  //   .then((response: Response<User>) => response.data)
  return axios
    .get(`${USER_URL}/getUserById/${id}`)
    .then((response: AxiosResponse<Response<User>>) => {
      console.log('Axios Response:', response)
      return response.data as User
    })
}

const getUserByIdForEdit = (id: ID): Promise<User | undefined> => {

  return axios
    .get(`${USER_URL}/getUserById/${id}`)
    .then((response: Response<User>) => response.data)
}

const createUser = async (user: User): Promise<number | void> => {
  // return axios
  //   .put(`${USER_URL}/createUser`, user)
  //   .then((response: AxiosResponse<Response<number>>) => {
  //     return response.status
  //   });
  try {
    const response: AxiosResponse<Response<number>> = await axios.put(`${USER_URL}/createUser`, user)
    return response.status
  } catch (error) {
    await infoWindow('The user cannot be created with the same userName of an existing user')
  }
}


const updateUser = (user: User): Promise<User | undefined> => {

  return axios
    .post(`${USER_URL}/updateUser/${user.userID}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/deleteUser/${userId}`).then(() => {
  })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getAllAgents = (items_per_page:number):Promise<any[]|undefined> => {
  return  axios
    .get(`${AGENT_URL}/getAllAgents?items_per_page=${items_per_page}`)
    .then((response:AxiosResponse<Response<Agent[]>>) => {
      return response.data as any[]})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getUserByIdForEdit, getAllAgents}
