import {ID, Response} from '../../../../../../_metronic/helpers'

export type Client = {
  clientId?: ID
  clientName?: String
  clientByAgentId?: number
  clientByAgentUserId?: number
  clientIsDelete?: boolean
  clientAgentName?: string

}

export type ClientsQueryResponse = Response<Array<Client>>

export const initialClient: Client = {
  clientName: '',
  clientIsDelete: false,
}
