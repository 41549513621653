import clsx from 'clsx'
import {ID, initialQueryState, KTSVG, toAbsoluteUrl, useDebounce} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search} from '../../../partials'
import {useLayout} from '../../core'
import {MultiSelect} from 'react-multi-select-component'
import React, {useEffect, useMemo, useState} from 'react'
import {getAgentByClientId} from '../../../../app/modules/apps/agent-management/agents-list/core/_requests'
import {
  useQueryResponseData as useQueryResponseData_client,
} from '../../../../app/modules/apps/client-management/clients-list/core/QueryResponseProvider'
import {useQueryRequest} from '../../../../app/modules/emaildigest/core/EmailDigestQueryRequestProvider'
import {loadGoogleScripts} from 'react-google-charts-ts/build/loadGoogleScripts'
import {left} from '@popperjs/core'
import {
  rowPerPage,
} from '../../../../app/modules/content/components/masters-list/components/pagination/MastersListPagination'
import {useAuth} from '../../../../app/modules/auth'
import axios from 'axios'
import {Agent} from '../../../../app/modules/apps/agent-management/agents-list/core/_models'
import {getTopicForFilter} from '../../../../app/modules/topic/core/_requests'
import {stringify} from 'qs'


const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'


export var selectedClientIdSource: string = ''

const Navbar = () => {
  const {config} = useLayout()
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [rowInAPage, setRowInAPage] = useState(rowPerPage)
  setInterval(() => {
    setRowInAPage(rowPerPage)
  }, 999)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)


  const user = useAuth().currentUser?.userRole


  //Get Client Select
  const client = useQueryResponseData_client()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => client, [client])

  // const ClientPreFilter = [];
  const [ClientPreFilter, setClientPreFilter] = useState<any[]>([]);
  const clientSourceList = data;

  useEffect(() => {
      (async () => {
        const tempClientPreFilterList = [];
        const tempClientPreFilterListForAgent = [];
        for (let clientSource of clientSourceList){
          if (!clientSource.clientId) continue;
          let agent = await getAgentByClientId(clientSource.clientId);
          tempClientPreFilterList.push(
            {
              label: `${clientSource.clientName} (Agent: ${agent?.agentName})`,
              value: clientSource.clientId
            });
          tempClientPreFilterListForAgent.push(
            {
              label: `${clientSource.clientName}`,
              value: clientSource.clientId
            });
        }
        if (user === 'SYSTEM_ADMIN'){
          setClientPreFilter(tempClientPreFilterList);
        } else if (user === 'AGENT'){
          setClientPreFilter(tempClientPreFilterListForAgent);
        }

      })();
    }, [data]);

  const [selectedClientSources, setSelectedClientSources] = useState<any[]>([]);


// Effect for API call
  useEffect(
    () => {
      selectedClientIdSource = ''
      for (let i = 0; i < selectedClientSources.length; i++) {
        selectedClientIdSource += selectedClientSources[i].value + ','
      }
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {

        updateState({
          clients: selectedClientIdSource,
          page: 1,
          items_per_page: rowInAPage as 10 | 20 | 50 | 100 | 250 | undefined,
        })
      }
    },
    [debouncedSearchTerm, selectedClientSources, rowInAPage], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  if (user === 'AGENT' || user === 'SYSTEM_ADMIN') {
    // @ts-ignore
    return (
      <div className="app-navbar flex-shrink-0"
           style={{width: '100%', display: 'flex', alignItems: 'center', position: 'relative'}}>
        <div className="d-flex align-items-center position-relative my-1" style={{marginRight: 'auto'}}>
          <MultiSelect
            className="form-control form-control-solid w-250px p-0"
            options={ClientPreFilter}
            value={selectedClientSources}
            labelledBy={'All clients selected. Click to change'}
            valueRenderer={() => 'Filter by clients'}
            onChange={setSelectedClientSources}
            hasSelectAll={false}
          />
        </div>

        <div className={clsx('app-navbar-item', itemClass)} style={{position: 'relative'}}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <img
              src={toAbsoluteUrl('/media/avatars/blank.png')}
              alt=""
            />
          </div>
          <HeaderUserMenu/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="app-navbar flex-shrink-0">
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt=""/>
          </div>
          <HeaderUserMenu/>
        </div>
      </div>
    )
  }


}

export {Navbar}
