import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {agentsColumns} from './columns/_columns'
import {Agent} from '../core/_models'
import {AgentsListLoading} from '../components/loading/AgentsListLoading'
import {AgentsListPagination} from '../components/pagination/AgentsListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'

const AgentsTable = () => {
  const agent = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => agent, [agent])
  const columns = useMemo(() => agentsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  // console.log(data)

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map((column: ColumnInstance<Agent>) => (
              <CustomHeaderColumn key={column.id} column={column}/>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<Agent>, i) => {
              prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No matching records found
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <AgentsListPagination/>
      {isLoading && <AgentsListLoading/>}
    </KTCardBody>
  )
}

export {AgentsTable}
