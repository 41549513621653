import clsx from "clsx";
import { useQueryResponseLoading, useQueryResponsePagination } from "../../core/MasterQueryResponseProvider";
import { useQueryRequest } from "../../core/MasterQueryRequestProvider";
import React, {useState} from 'react'
import {selectedClientIdSource} from '../../../../../../../_metronic/layout/components/header/Navbar'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }
  if (label === 'Next &raquo;') {
    return 'Next'
  }
  return label
}

export var rowPerPage: number = 10

const MastersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const pageSizes: number[] = [10, 20, 50, 100, 250]

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  // @ts-ignore
  const checkEntries = (entries: number | undefined) => {
    console.log("itemsperpage:", pagination.items_per_page, "entries:", entries)
    return (entries && entries > (pagination.page * pagination.items_per_page)) ? (pagination.page * pagination.items_per_page) : entries
  }
  const [message, setMessage] = useState('')

  const reloadPage = (perPage: 10 | 20 | 50 | 100 | 250) => {
    if (isLoading) {
      return
    }
    rowPerPage = perPage


    const page = pagination.page
    pagination.items_per_page = perPage
    console.log("perpage:",perPage)
    updateState({page, items_per_page: perPage})
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div className="fs-6 fw-bold text-gray-700">
          Showing&nbsp;{(pagination.page - 1) * pagination.items_per_page + 1}&nbsp;to
          &nbsp;{(checkEntries(pagination.total) || pagination.items_per_page)}&nbsp;of&nbsp;
          {pagination.total}&nbsp;entries
        </div>
        <div>
          <select
            className="form-select form-select-sm form-select-white w-auto h-35px mx-2"
            data-kt-select2="true"
            data-placeholder="Select option"
            data-allow-clear="true"
            data-kt-user-table-filter="role"
            data-hide-search="true"
            defaultValue="recordtime"
            onChange={(event) => {
              reloadPage(parseInt(event.target.value) as 10 | 20 | 50 | 100)
            }}
          >
            {pageSizes.map((size, index) => (
              <option key={index} value={size}>{size}</option>
            ))}
          </select>
        </div>
      </div>


      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link) => {
                // console.log(pagination)
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                      'disabled': link.label === '... ' || link.label === ' ...'
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>

        <div>
          <input
            type='text'
            className='form-control form-control-solid w-75px'
            placeholder='Page'
            min='0'
            max={String(Math.floor(Number(pagination.total) / Number(pagination.items_per_page) + 1))}
            value={message}
            onChange={event => {
              let {value, min, max} = event.target
              value = String(Math.max(Number(min), Math.min(Number(max), Number(value))))
              setMessage(value)
              console.log(event.target)
            }}

            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }

            }}

            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                updatePage(Number(message))
                // console.log(message)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
export {MastersListPagination}
