/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import {TopicQueryResponseProvider} from '../../modules/topic/core/TopicQueryResponseProvider'
import {getTopicForDashBoard} from '../../modules/topic/core/_requests'
import {TopicQueryRequestProvider} from '../../modules/topic/core/TopicQueryRequestProvider'
import {
  MasterQueryResponseProvider,
} from '../../modules/content/components/masters-list/core/MasterQueryResponseProvider'

import {
  MasterQueryRequestProvider,
} from '../../modules/content/components/masters-list/core/MasterQueryRequestProvider'
import {ActiveTopicsChart} from './Chart/ActiveTopicsCard'
import {LastWeekMessages} from './Chart/LastWeekMessages'
import {ActiveKeywordsChart} from './Chart/ActiveKeywordsCard'
import {LastMonthMessages} from './Chart/LastMonthMessages'
import {TopicStatisticTable} from './Chart/TopicStatisticTable'
import {Topic} from '../../modules/topic/core/_models'
import {getDashBoard} from './core/_requests'
import {createSecureServer} from 'http2'
import {useAuth} from '../../modules/auth'
import {selectedClientIdSource} from '../../../_metronic/layout/components/header/Navbar'
import {email} from 'react-admin'
import {EmailDigestDashboard} from './Chart/EmailDigestDashboard'
import {VideoTopicSummary} from './Chart/VideoTopicSummary'
import {WikiDefenderSummary} from './Chart/WikiDefenderSummary'


type Props = {
  activeTopicNum: number,
  messageLastDays: number,
  messageLastThirtyDays: number,
  activeKeywordNum: number,
  commentLastDays: number,
  commentLastThirtyDays: number,
  conversationLastDays: number,
  conversationLastThirtyDays: number,
  topicTicketWithOpenStatus: number,
  topicTicketWithProgressStatus: number,
  videoTopicNum: number,
  videoTopicKeywordNum: number,
  videoLastDays: number,
  videoLastThirtyDays: number,
  videoTopicTicketWithOpenStatus: number,
  videoTopicTicketWithProgressStatus: number,
  emailDigestNum: number,
  emailSentDay: number,
  emailSentMonth: number,
  wikiDefenderNum: number,
  detectedDay: number,
  detectedMonth: number,
  topicList: Topic[],

}


const DashboardPage: FC<Props> = ({
                                    activeTopicNum,
                                    messageLastDays,
                                    messageLastThirtyDays,
                                    activeKeywordNum,
                                    topicList,
                                    commentLastDays,
                                    commentLastThirtyDays,
                                    conversationLastDays,
                                    conversationLastThirtyDays,
                                    topicTicketWithOpenStatus,
                                    topicTicketWithProgressStatus,
                                    videoTopicNum,
                                    videoTopicKeywordNum,
                                    videoLastDays,
                                    videoLastThirtyDays,
                                    videoTopicTicketWithOpenStatus,
                                    videoTopicTicketWithProgressStatus,
                                    emailDigestNum,
                                    emailSentDay,
                                    emailSentMonth,
                                    wikiDefenderNum,
                                    detectedDay,
                                    detectedMonth,
                                  }) => (

  <>
    {(window.innerWidth >= 990) ?
      // Desktop view
      <>
        <div className="row g-3 g-xl-10 mb-5 mb-xl-10">
          <div className="col-xl-6 ">
            <ActiveTopicsChart className={'min-h-md-300px mb-5'}
                               topicNum={activeTopicNum} activeKeywordNum={activeKeywordNum}
                               conversationLastDays={conversationLastDays}
                               conversationLastThirtyDays={conversationLastThirtyDays}
                               topicTicketWithOpenStatus={topicTicketWithOpenStatus}
                               topicTicketWithProgressStatus={topicTicketWithProgressStatus}/>
            {/*<LastWeekMessages className={'min-h-md-500px mb-5 '} description={'Messages (Last 24 hours)'}*/}
            {/*                  color={`#FFFFFF`} stats={messageLastDays}/>*/}
            <EmailDigestDashboard
              className={'min-h-md-300px mb-5'}
              activeEmailDigestNum={emailDigestNum}
              emailSentDay={emailSentDay}
              emailSentMonth={emailSentMonth}/>
          </div>
          <div className="col-xl-6 ">
            <VideoTopicSummary
              className={'min-h-md-300px mb-5'}
              videoTopicNum={videoTopicNum}
              videoTopicKeywordNum={videoTopicKeywordNum}
              videoLastDays={videoLastDays}
              videoLastThirtyDays={videoLastThirtyDays}
              videoTopicTicketWithOpenStatus={videoTopicTicketWithOpenStatus}
              videoTopicTicketWithProgressStatus={videoTopicTicketWithProgressStatus}/>
            <WikiDefenderSummary
              className={'min-h-md-300px mb-5'}
              activeWikiDefenderNum={wikiDefenderNum}
              detectedDay={detectedDay}
              detectedMonth={detectedMonth}/>
            {/*<ActiveKeywordsChart className='min-h-md-300px mb-xl-10' keywordNum={activeKeywordNum}/>*/}
            {/*<LastMonthMessages className='h-md-100 mb-5 mb-xl-10' description='Messages (Last 30 days)'*/}
            {/*                   color={`#FFFFFF`} stats={messageLastThirtyDays}/>*/}
          </div>

          {/*<div className='col-xl-3 '>*/}
          {/*    <LastWeekMessages className={'h-md-50 mb-5 mb-xl-10'} description={'Conversations (Last 24 hours)'}*/}
          {/*                      color={`#fff5f8`} stats={conversationLastDays}/>*/}
          {/*    <LastWeekMessages className={'h-md-50 mb-5 mb-xl-10'} description={'Comments (Last 24 hours)'}*/}
          {/*                      color={`#FFFFFF`} stats={commentLastDays}/>*/}
          {/*</div>*/}

          {/*<div className='col-xl-3 '>*/}
          {/*    <LastMonthMessages className="h-md-50 mb-5 mb-xl-10" description="Conversations (Last 30 days)"*/}
          {/*                       color={`#f1faff`} stats={conversationLastThirtyDays}/>*/}
          {/*    <LastMonthMessages className="h-md-50 mb-5 mb-xl-10" description="Comments (Last 30 days)"*/}
          {/*                       color={`#FFFFFF`} stats={commentLastThirtyDays}/>*/}
          {/*</div>*/}

        </div>
        <div className="row gx-5 gx-xl-10">
          <div className="col-xxl-6 mb-5 mb-xl-10"/>
        </div>
                {/*<div className='col-xl-12'>*/}
                {/*    <TopicStatisticTable className='card-xxl-stretch mb-5 mb-xl-8' topics={topicList}/>*/}
                {/*</div>*/}
            </>
            :
            // Mobile View
            <>
                <div className='row g-3 g-xl-10 mb-5 mb-xl-10'>
                    <div className='col-xl-3 '>
                        <ActiveTopicsChart className={'h-md-50 mb-5 mb-xl-10'} topicNum={activeTopicNum}/>
                        <ActiveKeywordsChart className='h-md-50 mb-5 mb-xl-10' keywordNum={activeKeywordNum}/>
                        <LastWeekMessages className={'h-md-50 mb-5 mb-xl-10'} description={'Conversations (Last 24 hours)'}
                                          color={`#fff5f8`} stats={conversationLastDays}/>
                        <LastMonthMessages className='h-md-50 mb-5 mb-xl-10' description='Conversations (Last 30 days)'
                                           color={`#f1faff`} stats={conversationLastThirtyDays}/>
                        <LastMonthMessages className='h-md-50 mb-5 mb-xl-10' description='Messages (Last 24 hours)'
                                           color={`#FFFFFF`} stats={messageLastDays}/>
                        <LastMonthMessages className='h-md-50 mb-5 mb-xl-10' description='Messages (Last 30 days)'
                                           color={`#FFFFFF`} stats={messageLastThirtyDays}/>
                        <LastWeekMessages className={'h-md-50 mb-5 mb-xl-10'} description={'Comments (Last 24 hours)'}
                                          color={`#FFFFFF`} stats={commentLastDays}/>
                        <LastMonthMessages className='h-md-50 mb-5 mb-xl-10' description='Comments (Last 30 days)'
                                           color={`#FFFFFF`} stats={commentLastThirtyDays}/>

                    </div>

                </div>
                <div className='row gx-5 gx-xl-10'>
                    <div className='col-xxl-6 mb-5 mb-xl-10'/>
                </div>
                <div className='col-xl-12'>
                    <TopicStatisticTable className='card-xxl-stretch mb-5 mb-xl-8' topics={topicList}/>
                </div>
            </>
        }
    </>
)


const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const [topicNum, setTopicNum] = useState<any>('--')
  const [keywordNum, setKeywordNum] = useState<any>('--')
  const [messageLastDays, setMessageLastDays] = useState<any>('--')
  const [messageLastThirtyDays, setMessageLastThirtyDays] = useState<any>('--')
  const [commentLastDays, setCommentLastDays] = useState<any>('--')
  const [commentLastThirtyDays, setCommentLastThirtyDays] = useState<any>('--')
  const [conversationLastDays, setConversationLastDays] = useState<any>('--')
  const [conversationLastThirtyDays, setConversationLastThirtyDays] = useState<any>('--')
  const [topicTicketWithOpenStatus, setTopicTicketWithOpenStatus] = useState<any>('--')
  const [topicTicketWithProgressStatus, setTopicTicketWithProgressStatus] = useState<any>('--')

  const [videoTopicNum, setVideoTopicNum] = useState<any>('--')
  const [videoTopicKeywordNum, setVideoTopicKeywordNum] = useState<any>('--')
  const [videoLastDays, setVideoLastDays] = useState<any>('--')
  const [videoLastThirtyDays, setVideoLastThirtyDays] = useState<any>('--')
  const [videoTopicTicketWithOpenStatus, setVideoTopicTicketWithOpenStatus] = useState<any>('--')
  const [videoTopicTicketWithProgressStatus, setVideoTopicTicketWithProgressStatus] = useState<any>('--')

  const [emailDigestNum, setEmailDigestNum] = useState<any>('--')
  const [emailSentDay, setEmailSentDay] = useState<any>('--')
  const [emailSentMonth, setEmailSentMonth] = useState<any>('--')

  const [wikiDefenderNum, setWikiDefenderNum] = useState<any>('--')
  const [detectedDay, setDetectedDay] = useState<any>('--')
  const [detectedMonth, setDetectedMonth] = useState<any>('--')

  const [topicStat, setTopicStat] = useState<Topic[]>()
  const [isLoading, setIsLoading] = useState(false)

  const [selectedClientId, setSelectClientId] = useState(selectedClientIdSource)
  setInterval(() => {
    setSelectClientId(selectedClientIdSource)
  }, 600)

  const {currentUser} = useAuth()
  console.log(currentUser?.userID)


  useEffect(() => {
    setMessageLastThirtyDays('--')
    setMessageLastDays('--')
    setTopicNum('--')
    setKeywordNum('--')
    setCommentLastDays('--')
    setCommentLastThirtyDays('--')
    setConversationLastDays('--')
    setConversationLastThirtyDays('--')
    setTopicTicketWithOpenStatus('--')
    setTopicTicketWithProgressStatus('--')

    setVideoTopicNum('--')
    setVideoTopicKeywordNum('--')
    setVideoLastDays('--')
    setVideoLastThirtyDays('--')
    setVideoTopicTicketWithProgressStatus('--')
    setVideoTopicTicketWithOpenStatus('--')

    setEmailDigestNum('--')
    setEmailSentDay('--')
    setEmailSentMonth('--')

    setWikiDefenderNum('--')
    setDetectedDay('--')
    setDetectedMonth('--')

    getDashBoard(`userId=${currentUser?.userID}&clientId=${selectedClientId}`).then(res => {
      setMessageLastThirtyDays(res.messageLastThirtyDays!)
      setMessageLastDays(res.messageLastDays!)
      setTopicNum(res.activeTopicNum!)
      setKeywordNum(res.activeKeywordNum!)
      setCommentLastDays(res.commentLastDays!)
      setCommentLastThirtyDays(res.commentLastThirtyDays!)
      setConversationLastDays(res.conversationLastDays!)
      setConversationLastThirtyDays(res.conversationLastThirtyDays!)
      setTopicTicketWithOpenStatus(res.openedTopicTicket!)
      setTopicTicketWithProgressStatus(res.progressTopicTicket!)

      setVideoTopicNum(res.activeVideoTopicNum!)
      setVideoTopicKeywordNum(res.activeVideoTopicKeywordNum!)
      setVideoLastDays(res.currentDaysVideo!)
      setVideoLastThirtyDays(res.currentThirtyDaysVideo!)
      setVideoTopicTicketWithOpenStatus(res.openedVideoTopicTicket!)
      setVideoTopicTicketWithProgressStatus(res.progressVideoTopicTicket!)

      setEmailDigestNum(res.activeEmailDigestNum!)
      setEmailSentDay(res.emailSentDay!)
      setEmailSentMonth(res.emailSentMonth!)

      setWikiDefenderNum(res.activeWikiDefenderNum!)
      setDetectedDay(res.changeDetectedDay!)
      setDetectedMonth(res.changeDetectedMonth!)
    })
  }, [selectedClientId])

    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <DashboardPage activeTopicNum={topicNum}
                       messageLastDays={messageLastDays}
                       messageLastThirtyDays={messageLastThirtyDays}
                       activeKeywordNum={keywordNum}
                       topicList={isLoading ? [] : topicStat!}
                       commentLastDays={commentLastDays}
                       commentLastThirtyDays={commentLastThirtyDays}
                       conversationLastDays={conversationLastDays}
                       conversationLastThirtyDays={conversationLastThirtyDays}
                       topicTicketWithOpenStatus={topicTicketWithOpenStatus}
                       topicTicketWithProgressStatus={topicTicketWithProgressStatus}
                       videoTopicNum={videoTopicNum}
                       videoTopicKeywordNum={videoTopicKeywordNum}
                       videoLastDays={videoLastDays}
                       videoLastThirtyDays={videoLastThirtyDays}
                       videoTopicTicketWithOpenStatus={videoTopicTicketWithOpenStatus}
                       videoTopicTicketWithProgressStatus={videoTopicTicketWithProgressStatus}
                       emailDigestNum={emailDigestNum}
                       emailSentDay={emailSentDay}
                       emailSentMonth={emailSentMonth}
                       wikiDefenderNum={wikiDefenderNum}
                       detectedDay={detectedDay}
                       detectedMonth={detectedMonth}
        />
      </>
    )
}

export {DashboardWrapper}
