import {ID, Response} from '../../../../../../_metronic/helpers'
import {appendFile} from 'fs'

export type Agent = {
  agentId?: ID
  agentName?: String
  agentIsDelete?: boolean
  agentLogo?: File
  agentLogoName?: string
  youtubeUrl?: string
  linkedinUrl?: string
  instagramUrl?: string
  facebookUrl?: string
}

export type AgentsQueryResponse = Response<Array<Agent>>

export const initialAgent: Agent = {
  agentId: 1,
  agentName: '',
  agentIsDelete: false,
  youtubeUrl: '',
  linkedinUrl: '',
  instagramUrl: '',
  facebookUrl: '',
  agentLogoName: '',
}


