import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Agent, AgentsQueryResponse} from './_models'


const AGENT_URL = `/api/private/agent`

const getAgents = (query: string): Promise<AgentsQueryResponse> => {
  return axios
    .get(`${AGENT_URL}/getAgentList?${query}`)
    .then((d: AxiosResponse<AgentsQueryResponse>) => d.data)
}
const getAgentByClientId = (id: ID): Promise<Agent | undefined> => {
  return axios
    .get(`${AGENT_URL}/getAgentByClientId/${id}`)
    .then((response: Response<Agent>) => response.data)
    .then((data) => {return data})
}
const getAgentById = (id: ID): Promise<Agent | undefined> => {
  return axios
    .get(`${AGENT_URL}/getAgentById/${id}`)
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<Agent>) => response.data)
}

const createAgent = (agent: Agent): Promise<Agent | undefined> => {
  return axios
    .put(`${AGENT_URL}/createAgent`, agent)
    .then((response: AxiosResponse<Response<Agent>>) => response.data)
    .then((response: Response<Agent>) => response.data)
}


const updateAgent = (agent: Agent): Promise<Agent | undefined> => {
  // console.log(client)
  return axios
    .post(`${AGENT_URL}/updateAgent/${agent.agentId}`, agent)
    .then((response: AxiosResponse<Response<Agent>>) => response.data)
    .then((response: Response<Agent>) => response.data)
}

const deleteAgent = (agentId: ID): Promise<void> => {
  return axios.delete(`${AGENT_URL}/deleteAgent/${agentId}`).then(() => {
  })
}

const deleteSelectedAgent = (agentId: Array<ID>): Promise<void> => {
  const requests = agentId.map((id) => axios.delete(`${AGENT_URL}/${id}`))
  return axios.all(requests).then(() => {
  })
}

// const uploadLogo = (uploadFile: any): Promise<void> => {
//   return axios.post(`${AGENT_URL}/uploadLogo/${uploadFile}`, uploadFile).then((response) => {
//     response.status
//   })
// }

export {getAgents, getAgentById, createAgent, updateAgent, deleteAgent, deleteSelectedAgent, getAgentByClientId}
