import {ReactNode} from 'react'
import {MenuComponent} from '../assets/ts/components'

export type WithChildren = {
  children?: ReactNode
}

const reInitMenu = () => {
  setTimeout(() => {
    MenuComponent.reinitialization()
  }, 500)
}

// export default WithChildren
export {reInitMenu}
