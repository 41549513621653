type Props = {
  className: string
  videoTopicNum: number
  videoTopicKeywordNum: number
  videoLastDays: number,
  videoLastThirtyDays: number
  videoTopicTicketWithOpenStatus: number,
  videoTopicTicketWithProgressStatus: number,
}

const VideoTopicSummary = ({
                             className,
                             videoTopicNum,
                             videoTopicKeywordNum,
                             videoLastDays,
                             videoLastThirtyDays,
                             videoTopicTicketWithOpenStatus,
                             videoTopicTicketWithProgressStatus,
                           }: Props) => (
  <div className={`card card-flush ${className}`} style={{backgroundColor: '#f1faff'}}>
    <div className="card-header pt-5">
      <div className="card-title d-flex flex-column">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">Video Topic Summary</span>
          </div>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Active topics: {videoTopicNum}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Active keywords: {videoTopicKeywordNum}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Videos (24 hours): {videoLastDays}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Videos (30 days): {videoLastThirtyDays}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Opened tickets: {videoTopicTicketWithOpenStatus}</span>
          <span
            className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Tickets in progress: {videoTopicTicketWithProgressStatus}</span>
        </div>
      </div>
    </div>
  </div>

)
export {VideoTopicSummary}