import {MasterQueryResponse, CommentQueryResponse, Master, RegionReportQueryResponse} from "./_models";
import axios, { AxiosResponse } from "axios";
import {ID, Response} from "../../../../../../_metronic/helpers";
import {Topic} from "../../../../topic/core/_models";

const API_URL = `/api/private/master`
const COMMENT_API_URL = '/api/private/comment'

export type downloadData={
  email: string,
  status?: string,
  createTs: number,
  lastUpdateTs: number,
  param: string

  type: string

}

const getMasterList = (query: string): Promise<MasterQueryResponse> => {
  // console.log(query)
  //  query = query.replace('+', "%2B")
  //               .replace('[', "%5B")
  //               .replace(']', "%5D")
  //               .replace('(', "%28")
  //               .replace(')', "%29")
  return axios
    .get(`${API_URL}/getMasterList?${query}`)
    .then((d: AxiosResponse<MasterQueryResponse>) => d.data)
}

const getMasterRegion = (query: string): Promise<RegionReportQueryResponse> => {
  return axios
      .get(`${API_URL}/getMasterRegion?${query}`)
      .then((d: AxiosResponse<RegionReportQueryResponse>) => d.data)
}

const getMasterRegion2 = (query: string): Promise<RegionReportQueryResponse> => {
  return axios
    .get(`${API_URL}/getMasterRegion2?${query}`)
    .then((d: AxiosResponse<RegionReportQueryResponse>) => d.data)
}

const getMasterById = (id: ID): Promise<Master | undefined> => {
  return axios
      .get(`${API_URL}/getMasterById/${id}`)
      .then((response: Response<Master>) => response.data)
}

const updateMaster = (master: Master): Promise<Master | undefined> => {
  return axios
      .post(`${API_URL}/updateMaster/${master.recordID}`, master)
      .then((response: AxiosResponse<Response<Master>>) => response.data)
      .then((response: Response<Master>) => response.data)
}

const deleteMaster= (masterId: number)=>{
  return axios
    .put(`${API_URL}/deleteMaster/${masterId}`)
    .then((response:AxiosResponse<Response<Master>>)=>response.data)
    .then((response: Response<Master>) => response.data)
}

const getCommentList = (query: string): Promise<CommentQueryResponse> => {
  return axios
    .get(`${COMMENT_API_URL}/getCommentList?${query}`)
    .then((d: AxiosResponse<CommentQueryResponse>) => d.data)
}

const addDownloadTicket=(queryBody:downloadData)=>{
  return axios
    .post(`/api/private/download/createDownloadTicket`,queryBody)
    .then(()=>{})
    .catch()
}

export {getMasterList, getCommentList, getMasterById, updateMaster, getMasterRegion, addDownloadTicket, deleteMaster, getMasterRegion2}
