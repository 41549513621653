import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {clientsColumns} from './columns/_columns'
import {Client} from '../core/_models'
import {ClientsListLoading} from '../components/loading/ClientsListLoading'
import {ClientsListPagination} from '../components/pagination/ClientsListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'

const ClientsTable = () => {
  const client = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => client, [client])
  const columns = useMemo(() => clientsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  // console.log(data)

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map((column: ColumnInstance<Client>) => (
              <CustomHeaderColumn key={column.id} column={column}/>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row<Client>, i) => {
              prepareRow(row)
              return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No matching records found
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <ClientsListPagination/>
      {isLoading && <ClientsListLoading/>}
    </KTCardBody>
  )
}

export {ClientsTable}
