import {useQuery} from 'react-query'
import {AgentEditModalForm} from './AgentEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAgentById} from '../core/_requests'

const AgentEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: agent,
    error,
  } = useQuery(
    `${QUERIES.AGENT_LIST}-agent-${itemIdForUpdate}`,
    () => {
      return getAgentById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    },
  )

  // console.log()

  if (!itemIdForUpdate) {
    return <AgentEditModalForm isUserLoading={isLoading} agent={{agentId: undefined}}/>
  }

  if (!isLoading && !error && agent) {
    return <AgentEditModalForm isUserLoading={isLoading} agent={agent}/>
  }

  return null
}

export {AgentEditModalFormWrapper}
