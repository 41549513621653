import { AuthModel } from "./_models";
import Swal from "sweetalert2";

const {PUBLIC_URL} = process.env

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {


  axios.defaults.headers.Accept = 'application/json'
  let flagForAuth = false
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      console.log(auth)
      if (auth && auth.token) {
        // config.headers.Authorization = `Bearer ${auth.token}`
        config.headers.Authorization = `${auth.token}`
        flagForAuth = true
      }
      console.log('auth ', auth)
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use((response: any) => {
    // Response
    console.log(flagForAuth)
    return responseSuccessHandling(response);
  }, (error: any) => {
    // Error
    console.log('error', error)

    if (window.location.pathname === '/auth') {
      return responseErrorHandlingForAuth(error)
    } else {
      return responseErrorHandlingForSession(error)
    }
    // if(flagForAuth){
    //   return responseErrorHandlingForSession(error);
    // }
    // else{
    //   return responseErrorHandlingForAuth(error);
    // }

  })
}

function responseSuccessHandling(res: any) {
  let token = res.headers.token;
  localStorage.setItem('token', token)
  return res;
}

function responseErrorHandlingForAuth(error: any) {
  switch (error?.response?.status) {
    case 401:
      Swal.fire({
        icon: 'info',
        title: `<strong>Unsuccessful Authentication</strong>`,
        html: `<i>Your credential cannot be found in system</i>`,
        heightAuto: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (window.location.pathname !== `${PUBLIC_URL}/auth`) {
            window.location.href = `${PUBLIC_URL}/auth`
          }
        }
      })
      break
    // case 404:
    //   Swal.fire({
    //     icon: 'error',
    //     title: `<strong>Oops....!</strong>`,
    //     html: `<i>Some Errors in backend endpoint !</i>`,
    //     heightAuto: false,
    //
    //   break;
    case 500:
      Swal.fire({
        icon: 'error',
        title: `<strong>Internal Error....!</strong>`,
        html: `<i>Please contact system admin for further action!</i>`,
        heightAuto: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      break
    default:
      break
  }

  return Promise.reject(error)
}

function responseErrorHandlingForSession(error: any) {
  switch (error?.response?.status) {
    case 401:
      Swal.fire({
        icon: 'info',
        title: `<strong>Session timeout</strong>`,
        html: `<i>For security reason, you are logged out from the system</i>`,
        heightAuto: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (window.location.pathname !== `${PUBLIC_URL}/auth`) {
            console.log('the error is confirmed')
            window.location.href = `${PUBLIC_URL}/auth`
          }
        }
      })
      break
    // case 404:
    //   Swal.fire({
    //     icon: 'error',
    //     title: `<strong>Oops....!</strong>`,
    //     html: `<i>Some Errors in backend endpoint !</i>`,
    //     heightAuto: false,
    //
    //   break;
    case 500:
      Swal.fire({
        icon: 'error',
        title: `<strong>Internal Error....!</strong>`,
        html: `<i>Please contact system admin for further action!</i>`,
        heightAuto: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      break
    default:
      break
  }

  return Promise.reject(error)
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
