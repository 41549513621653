import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
  className: string
  activeWikiDefenderNum: number,
  detectedDay: number,
  detectedMonth: number
}

const WikiDefenderSummary = ({className, activeWikiDefenderNum, detectedDay, detectedMonth}: Props) => (
  <div className={`card card-flush ${className}`} style={{backgroundColor: '#e6fff7'}}>
    <div className="card-header pt-5">
      <div className="card-title d-flex flex-column">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">Wiki Defender Summary</span>
          </div>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Active defenders: {activeWikiDefenderNum}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Change detected (24 hours): {detectedDay}</span>
          <span className="fs-2 text-gray-800 pt-4 fw-bold fs-6">Change detected (30 days): {detectedMonth}</span>
        </div>
      </div>
    </div>
  </div>

)
export {WikiDefenderSummary}