import {KeywordQueryResponse, Topic, TopicQueryResponse} from './_models'
import axios, { AxiosResponse } from "axios";
import {ID, Response} from '../../../../_metronic/helpers'
import {Client, ClientsQueryResponse} from '../../apps/client-management/clients-list/core/_models'

const API_URL = `/api/private/topic`
const CLIENT_API_URL = `/api/private/client`
const KEYWORD_API_URL = `/api/private/keyword`

const getTopicList = (query: string): Promise<TopicQueryResponse> => {
  return axios
    .get(`${API_URL}/getTopicList?${query}`)
    .then((d: AxiosResponse<TopicQueryResponse>) => d.data)
}

const getKeywordList = (query: string): Promise<KeywordQueryResponse> => {
  return axios
    .get(`${KEYWORD_API_URL}/getKeywordList?${query}`)
    .then((d: AxiosResponse<KeywordQueryResponse>) => d.data)
}

const getTopicById = (id: ID): Promise<Topic | undefined> => {
  return axios
    .get(`${API_URL}/getTopicById/${id}`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: Response<Topic>) => response.data)
}

const createTopic = (topic: Topic): Promise<Topic | undefined> => {
  if (topic.exclusionReturn != null) {topic.exclusion = JSON.stringify(topic.exclusionReturn, null, 0).toString()}
  if(topic.mediaReturn != null) {topic.media = JSON.stringify(topic.mediaReturn, null, 0).toString()}
  return axios
    .put(`${API_URL}/createTopic`, topic)
    .then((response: AxiosResponse<Response<Topic>>) => response.data)
    .then((response: Response<Topic>) => response.data)
}

const updateTopic = (topic: Topic): Promise<Topic | undefined> => {
  if (topic.exclusionReturn != null) {topic.exclusion = JSON.stringify(topic.exclusionReturn, null, 0).toString()}
  if(topic.mediaReturn != null) {topic.media = JSON.stringify(topic.mediaReturn, null, 0).toString()}
  return axios
    .post(`${API_URL}/updateTopic/${topic.id}`, topic)
    .then((response: AxiosResponse<Response<Topic>>) => response.data)
    .then((response: Response<Topic>) => response.data)
}

const deleteTopic = (topicId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/deleteTopic/${topicId}`).then(() => {})
}

const deleteSelectedTopics = (topicIds: Array<ID>): Promise<void> => {
  const requests = topicIds.map((id) => axios.delete(`${API_URL}/deleteTopic/${id}`))
  return axios.all(requests).then(() => {})
}

const getTopicForDashBoard = (query: string): Promise<TopicQueryResponse> => {
  return axios
      .get(`${API_URL}/getTopicForDashBoard?${query}`)
      .then((d: AxiosResponse<TopicQueryResponse>) => d.data)
}

const getTopicForFilter = (query: string): Promise<TopicQueryResponse> => {
  return axios
      .get(`${API_URL}/getTopicForFilter?${query}`)
      .then((d: AxiosResponse<TopicQueryResponse>) => d.data)
}


export {getTopicList, getKeywordList, getTopicById, createTopic, updateTopic, deleteTopic, deleteSelectedTopics, getTopicForDashBoard, getTopicForFilter}
