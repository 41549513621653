import axios from "axios";
import { AuthModel, UserModel } from "./_models";
import { sha256 } from "js-sha256";

const API_URL = process.env.REACT_APP_API_URL

// todo: need to change later
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export const Login_URL = `/api/login`
export const Get_USER_BY_ACCESSTOKEN_URL = `/api/private/user/getUserByToken`

// Server should return AuthModel
export function login(username: string, password: string) {
  let pwdHash = sha256(password);
  return axios.post<AuthModel>(Login_URL, null, {params: {
    "username": username,
    "pwdHash": pwdHash
  }})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(Get_USER_BY_ACCESSTOKEN_URL, null,
    {
      params: {token: token}
    })
}

