import axios, {AxiosResponse} from 'axios'
import {ID, infoWindow, Response} from '../../../../../../_metronic/helpers'
import {Client, ClientsQueryResponse} from './_models'
import {User} from '../../../user-management/users-list/core/_models'


const Client_URL = `/api/private/client`

const getClients = (query: string): Promise<ClientsQueryResponse> => {

  // axios
  //   .get(`${Client_URL}/getClientList?${query}`)
  //   .then((d: AxiosResponse<ClientsQueryResponse>) => {d.data; console.log(d.data)})

  return axios
    .get(`${Client_URL}/getClientList?${query}`)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

const getClientById = (id: ID): Promise<Client | undefined> => {
  return axios
    .get(`${Client_URL}/getClientById/${id}`)
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<Client>) => response.data)
}

// const createClient = (client: Client): Promise<Client | undefined> => {
//   console.log(client)
//   return axios
//     .put(`${Client_URL}/createClient`, client)
//     .then((response: AxiosResponse<Response<Client>>) => response.data)
//     .then((response: Response<Client>) => response.data)
// }

const createClient = async (client: Client): Promise<number | void> => {

  try {
    const response: AxiosResponse<Response<number>> = await axios.put(`${Client_URL}/createClient`, client)
    console.log(123122)
    return response.status
  } catch (error) {
    await infoWindow('The user cannot be created with the same userName of an existing user')
  }
}


const updateClient = (client: Client): Promise<Client | undefined> => {
  // console.log(client)
  return axios
    .post(`${Client_URL}/updateClient/${client.clientId}`, client)
    .then((response: AxiosResponse<Response<Client>>) => response.data)
    .then((response: Response<Client>) => response.data)
}

const deleteClient = (client: ID): Promise<void> => {
  return axios.delete(`${Client_URL}/deleteClient/${client}`).then(() => {
  })
}

const deleteSelectedClient = (clientId: Array<ID>): Promise<void> => {
  const requests = clientId.map((id) => axios.delete(`${Client_URL}/deleteClient/${id}`))
  return axios.all(requests).then(() => {
  })
}

export {getClients, getClientById, createClient, updateClient, deleteClient, deleteSelectedClient}
