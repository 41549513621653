const QUERIES = {
  USERS_LIST: 'users-list',
  MASTERS_LIST: 'masters-list',
  TOPICS_LIST: 'topics-list',
  PHISHINGTOPIC_LIST: 'phishingtopics-list',
  COMMENTS_LIST: 'commend-list',
  SHARE_OF_VOICE_LIST: 'sov-list',
  EMAILDIGEST_LIST: 'emailDigest-list',
  AGENT_LIST: 'agent-list',
  CLIENT_LIST: 'client-list',
}

export {QUERIES}
