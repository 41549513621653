import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialClient, Client} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ClientsListLoading} from '../components/loading/ClientsListLoading'
import {createClient, updateClient} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useNavigate} from 'react-router-dom'
import {getAgents} from '../../../agent-management/agents-list/core/_requests'
import {getAllAgents} from '../../../user-management/users-list/core/_requests'
import {Agent} from '../../../agent-management/agents-list/core/_models'
import Select from 'react-select'
import {InputActionMeta} from 'react-select'
import {json} from 'stream/consumers'
import {useAuth} from '../../../../auth'
import {auto} from '@popperjs/core'

type Props = {
  isUserLoading: boolean
  client: Client
}

const editUserSchema = Yup.object().shape({
  clientName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const ClientEditModalForm: FC<Props> = ({client, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  const [agentOptionList, setAgentOptionList] = useState<any[]>([])
  const [selectedAgent, setSelectedAgent] = useState<any[]>([])


  useEffect(() => {

    const getAgentList = async () => {
      const agentList = await getAllAgents(1000000) as Agent[]
      const tempAgentList: any[] = []
      agentList.forEach((agent) => {
        const tempValue = {value: agent.agentId, label: agent.agentName}

        tempAgentList.push(tempValue)

        if (client.clientByAgentId === agent.agentId) {
          setSelectedAgent([tempValue])
        }
      })
      await setAgentOptionList(tempAgentList)
    }

    getAgentList()

  }, [])


  const [userForEdit] = useState<Client>({
    ...client,
    clientName: client.clientName || initialClient.clientName,
  })

  // console.log(user)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.clientId)) {
          console.log('in update')
          await updateClient(values)
        } else {
          console.log('in create')
          // console.log(values)
          await createClient(values)
        }
      } catch (ex) {
        console.log('in error')
        console.log(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
        navigate('/apps/client-management/clients')
      }
    },
  })


  useEffect(() => {
    formik.values.clientByAgentId = selectedAgent[0]?.value
  }, [selectedAgent])

  return (
    <>
      <form
        id='kt_modal_add_user_form'
            className='form '
        style={{height:"100%"}}
        onSubmit={formik.handleSubmit} noValidate>

        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7 pt-15'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">Display Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Display name"
              {...formik.getFieldProps('clientName')}
              type='text'
              name='clientName'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.clientName && formik.errors.clientName},
                {
                  'is-valid': formik.touched.clientName && !formik.errors.clientName,
                },
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.clientName && formik.errors.clientName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.clientName}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          {useAuth().currentUser?.userRole === 'SYSTEM_ADMIN' && (
            <div
              className='fv-row mb-7'
            >
              {/* begin::Label */}
              <label className="required fw-bold fs-6 mb-2">Agent</label>
              {/* end::Label */}

              {/* begin::Input */}
              <Select
                {...formik.getFieldProps('clientByAgentId')}
                className={'col-sm me-1 mb-1 w-100 mt-0'}
                options={agentOptionList}
                value={selectedAgent}
                onChange={(event) => setSelectedAgent([event])}
              />
              {/* end::Input */}
            </div>
          )}

          {/* end::Input group */}


        </div>


        {/* begin::Actions */}
        <div className='text-center mt-15 pt-15'

        >
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'

            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ClientsListLoading/>}
    </>
  )
}

export {ClientEditModalForm}
