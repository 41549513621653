import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AgentsListHeader} from './components/header/AgentsListHeader'
import {AgentsTable} from './table/AgentsTable'
import {AgentEditModal} from './agent-edit-modal/AgentEditModal'
import {KTCard} from '../../../../../_metronic/helpers'

const AgentsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <AgentsListHeader/>
        <AgentsTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <AgentEditModal/>}
    </>
  )
}

const AgentsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AgentsList/>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AgentsListWrapper}
