import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AgentsListWrapper} from './agents-list/AgentsList'

const usersBreadcrumbs: Array<PageLink> = [
  // {
  //   title: 'Agent Management',
  //   path: '/apps/client-management/agents',
  //   isSeparator: false,
  //   isActive: false,
  // },
  // {
  //   title: '',
  //   path: '',
  //   isSeparator: true,
  //   isActive: false,
  // },
]

const AgentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet/>}>
        <Route
          path='agents'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Agent Management</PageTitle>
              <AgentsListWrapper/>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/agent-management/agents'/>}/>
    </Routes>
  )
}

export default AgentPage
