import * as React from 'react'
import {AbacProvider, AllowedTo} from 'react-abac'
import {ID} from '../../_metronic/helpers'
import * as authHelper from '../modules/auth/core/AuthHelpers'
import {getUserByToken} from '../modules/auth/core/_requests'
import {useState} from 'react'


const getAccountRole = async () => {
  const auth = authHelper.getAuth()
  const account = await getUserByToken(auth!.token)
  // console.log(account)

  switch (account.data.userRole) {
    case 'AGENT':
      return Role.AGENT
    case 'SYSTEM_ADMIN':
      return Role.ADMIN
    case 'CLIENT':
      return Role.CLIENT
    default:
      return Role.USER
  }

}


interface User {
  uuid: string;
  roles: Role[];
  permissions: permissions[];
}

interface Post {
  owner: string; // user uuid
}

// an object with all permissions
enum permissions {
  EDIT_POST = 'EDIT_POST',
  DASHBOARD = 'DASHBOARD',
  TOPICS = 'TOPICS',
  PHISHING_TOPICS = 'PHISHING_TOPICS',
  VIDEO_TOPICS = 'VIDEO_TOPICS',
  MESSAGE_AND_COMMENTS = 'MESSAGE_AND_COMMENTS',
  ALL_MESSAGES = 'ALL_MESSAGES',
  ALL_VIDEOS = 'ALL_VIDEOS',
  REPORTS = 'REPORTS',
  ACCOUNTS = 'ACCOUNTS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  AGENT_MANAGEMENT = 'AGENT_MANAGEMENT',
  CLIENT_MANAGEMENT = 'CLIENT_MANAGEMENT',
  EMAIL_DIGEST = 'EMAIL_DIGEST',
  WIKI_DEFENDER = 'WIKI_DEFENDER'
}

enum Role {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  CLIENT = 'CLIENT',
  USER = 'USER'
}


// rules describing what roles have what permissions
const rules: any = {
  [Role.ADMIN]: {
    // [permissions.EDIT_POST]: true,
    [permissions.ACCOUNTS]: true,
    [permissions.USER_MANAGEMENT]: true,
    [permissions.AGENT_MANAGEMENT]: true,
    [permissions.CLIENT_MANAGEMENT]: true,

    [permissions.TOPICS]: true,
    [permissions.PHISHING_TOPICS]: true,

    [permissions.VIDEO_TOPICS]: true,

    [permissions.EMAIL_DIGEST]: true,
    [permissions.WIKI_DEFENDER]: true,

    [permissions.MESSAGE_AND_COMMENTS]: true,
    [permissions.ALL_MESSAGES]: true,

    [permissions.ALL_VIDEOS]: true,
    [permissions.REPORTS]: true,
  },
  [Role.AGENT]: {
    // an abac rule
    // user can only edit the post if it is the owner of it
    // [permissions.EDIT_POST]: (post:any, user:any) => post.owner === user.uuid,

    [permissions.DASHBOARD]: true,
    [permissions.TOPICS]: true,
    [permissions.PHISHING_TOPICS]: true,

    [permissions.VIDEO_TOPICS]: true,
    [permissions.MESSAGE_AND_COMMENTS]: true,
    [permissions.ALL_MESSAGES]: true,

    [permissions.ALL_VIDEOS]: true,
    [permissions.REPORTS]: true,
    [permissions.ACCOUNTS]: true,
    [permissions.USER_MANAGEMENT]: true,

    // [permissions.USER_MANAGEMENT]: true,
    [permissions.CLIENT_MANAGEMENT]: true,
    [permissions.EMAIL_DIGEST]: true,
    [permissions.WIKI_DEFENDER]: true,
  },

  [Role.CLIENT]: {
    [permissions.REPORTS]: true,
    [permissions.ACCOUNTS]: true,
  },
}


interface Props {
  user: User;
  post: Post;
}


export {getAccountRole, rules, permissions, Role}